import React from 'react';
import Header from "../../components/Header/Header";
import ScrollLink from "../../lib/helpers/ScrollLink";
import TermsUsingCredit from "../../components/TermsUsingCredit/TermsUsingCredit";
import Footer from "../../components/Footer/Footer";

const RomanceCost = () => {
    return (
        <div className="stock">
            <div className="top_background" style={{ backgroundImage: 'none' }}>
                <div className="container">
                    <Header activePageName="stock" />
                    <div className="blog__wrapper">
                        <h1 className="title">У скільки обійдеться романтік?</h1>
                        <div className="blog__item" style={{ width: '100%' }}>
                            <div className="blog__desc">
                                Плануєте побачення? Де взяти гроші на романтік?
                                <br />
                                <br />
                                Запросіть свою другу половинку на побачення до кінотеатру. Сеанс цікавого українського чи зарубіжного фільму стане гарним початком зустрічі! Після цього можна вирушити на вечерю до затишного ресторанчику чи кав'ярні, а на завершення вечора можна подумати про розваги: боулінг, картинг чи караоке...
                                <br />
                                <br />
                                Плануючи побачення, подбайте про бюджет завчасно! Якщо відчуваєте, що попереду насичений вечір розваг і витрат, а баланс вашої картки майже на нулі, можете оформити швидкий онлайн кредит.
                                <br />
                                <br />
                                Подайте заявку ось тут <ScrollLink to="/">https://groshi247.com/</ScrollLink> і отримайте гроші на картку вже через 5 хвилин!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TermsUsingCredit />
            <Footer />
        </div>
    );
};

export default RomanceCost;
import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import ScrollLink from "../lib/helpers/ScrollLink";

const FaqPage = () => {
    return (
        <div className="faq_page">
            <div className="top_background">
                <div className="container">
                    <Header activePageName="faq" />
                    <div className="faq_page__wrapper">
                        <div className="title">FAQ</div>
                        <div className="faq__list">
                            <div className="faq__item">
                                <div className="faq__header">На яку суму можна отримати кредит на картку на сайті Groshi 24/7?</div>
                                <div className="faq__body">
                                    Ви можете отримати кредит онлайн на суму від 1 000,00 грн. до 50 000,00 грн. Термін кредитування може бути обраний у межах від 7 днів до 120 днів, залежно від умов банку або фінансової організації, що надає кредит.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Що потрібно для оформлення мікрозайму?</div>
                                <div className="faq__body">
                                    Для отримання кредиту, зазвичай потрібно наступне:
                                    <br />
                                    1. Комп'ютер або мобільний телефон з доступом до Інтернету
                                    <br />
                                    2. Банківська картка будь-якого банку України, оформлена на ваше ім'я. Вона може використовуватися для отримання коштів або погашення кредиту.
                                    <br />
                                    3. Паспорт для ідентифікації вас як заявника кредиту.
                                    <br />
                                    4. Ідентифікаційний код - це унікальний ідентифікатор, присвоєний кожному громадянину України.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">До якого віку дають споживчий кредит?</div>
                                <div className="faq__body">
                                    Вимоги до позичальників: громадянство України, вік від 18 до 60 років, відсутність значних прострочень у кредитній історії.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">У якому місті можна отримати кредит?</div>
                                <div className="faq__body">
                                    На підконтрольній території України. Кредит оформляється на сайті, кошти перераховуються на вашу банківську карту.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Щоб підписати договір мені треба відвідувати ваш офіс?</div>
                                <div className="faq__body">
                                    Ні, вам не потрібно відвідувати офіс Гроші 24/7 для підписання договору. Вся процедура оформлення кредиту здійснюється он-лайн. Ви можете подати заявку через Інтернет, і договір буде підписано в електронному вигляді. Після підписання договору кошти буде перераховано на вказану вами банківську картку.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Які документи необхідні для отримання кредиту в Гроші 24/7?</div>
                                <div className="faq__body">
                                    Для отримання он-лайн кредиту в Гроші 24/7 вам необхідно мати наступні документи:
                                    <br />
                                    <br />
                                    Паспорт. Дані з вашого паспорта будуть використовуватися для ідентифікації вас як заявника.
                                    <br />
                                    <br />
                                    Ідентифікаційний код, який також буде використовуватися для ідентифікації вас як заявника.
                                    <br />
                                    <br />
                                    Під час підписання Договору вам не потрібно сканувати будь-які документи, оскільки вся процедура здійснюється он-лайн. Під час первинного подання заявки ви заповнюєте анкету і вказуєте данні вашого паспорта та ідентифікаційного коду. Під час наступних позик ваші дані будуть збережені, і ви зможете отримувати кошти, вказуючи тільки суму і термін кредиту без необхідності повторної ідентифікації.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Для отримання кредиту потрібні застави, поручителі або довідка про доходи?</div>
                                <div className="faq__body">
                                    Гроші 24/7, як правило, видає кредити на споживчі потреби без застави та поручителів і без довідки про доходи. Беззаставний он-лайн кредит не потребує вищевказаних документів. Отримання кредиту без довідки про доходи ґрунтуються на інших критеріях, таких як ваша особиста кредитна історія, спроможність повернути кредит та інші фактори.
                                    <br />
                                    <br />
                                    Кредит без поручителів може бути зручний для тих, хто не може надати заставу або поручителя, або не хоче ділитися своїми даними про доходи. Однак, важливо пам'ятати, що через ці фактори відсоткові ставки на беззаставні кредити вищі. Тим самим компенсується ризик, пов'язаний з відсутністю застави або поручителя.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">На яку картку можна отримати кредит?</div>
                                <div className="faq__body">
                                    Ви можете отримати кредит на картку будь-якого українського банку, за умови, що ця картка активна і оформлена на ваше ім'я.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Чи можу я отримати кредит у Гроші 24/7, якщо у мене вже є кредит в іншій організації?</div>
                                <div className="faq__body">
                                    Так, за умови, що у вас не було значних заборгованостей і прострочень в обслуговуванні попередніх позик.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Скільки кредитив одночасно я можу оформити в Гроші 24/7?</div>
                                <div className="faq__body">
                                    На сьогодні в сервісі Гроші 24/7 ви не зможете отримати одночасно декілька кредитів. Новий кредит ви зможете отримати тільки після повного погашення попереднього.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Як оформити заявку на кредит?</div>
                                <div className="faq__body">
                                    Процедура оформлення кредиту – максимально проста. Для подачі заявки вам необхідно перейти за посиланням <ScrollLink to="/">https://groshi247.com/</ScrollLink> і виконати 4 прості кроки:
                                    <br />
                                    1. Обрати суму й строк погашення кредиту за допомогою відповідних “бігунків” на кредитному калькуляторі й натиснути “Оформити кредит”;
                                    <br />
                                    2. Зареєструватися на сайті, пройшовши ідентифікацію через систему BankID, і заповнити заявку;
                                    <br />
                                    3. Підписати кредитний договір, ввівши код-ідентифікатор, який ви отримаєте SMS-повідомленням;
                                    <br />
                                    4. Подати заявку й отримати рішення.
                                    <br />
                                    <br />
                                    ВСЕ!
                                    <br />
                                    Обробка даних повністю автоматизована, і в разі позитивного рішення по заявці кредитні кошти надходять на вашу картку вже через 5 хвилин!
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Скільки часу розглядається заявка та перераховуються кошти на картку?</div>
                                <div className="faq__body">
                                    Процес прийняття рішень повністю автоматизований. Це означає, що рішення про видачу кредиту система приймає протягом 2-10 секунд. Після ухвалення позитивного рішення гроші будуть зараховані на вашу картку за 2 хвилини.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Як оформити кредит по телефону?</div>
                                <div className="faq__body">
                                    Звичайно, ви можете зателефонувати до кол-центру компанії і отримати відповіді на ваші запитання, щодо оформлення позики. Але сам процес оформлення кредиту обов'язково повинен бути завершений вами на сайті.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Що робити, якщо я вказав невірні дані в заявці?</div>
                                <div className="faq__body">
                                    Не хвилюйтеся, якщо ви вказали невірні дані в заявці. Просто увійдіть у свій особистий кабінет на сайті та внесіть необхідні корективи у свої дані. Якщо у вас виникнуть додаткові запитання, не соромтеся ставити їх телефоном.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Як дізнатися рішення за кредитом?</div>
                                <div className="faq__body">
                                    Ви отримаєте рішення за кредитом одразу після заповнення заявки прямо на сайті. Крім того, позитивне рішення буде надіслано на вашу електронну адресу та у вигляді SMS-повідомлення.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Навіщо проводити перевірку картки?</div>
                                <div className="faq__body">
                                    Банківська картка повинна належати тільки вам для того, щоб шахраї не могли оформити кредит з видачею на чужу картку.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Що таке CVV/CVC-код, і навіщо його вводити під час оформлення заявки?</div>
                                <div className="faq__body">
                                    CVV / CVC-код (Card Verification Value / Card Verification Code) - це тризначний код на зворотному боці вашої банківської картки. Він використовується для перевірки автентичності картки під час проведення операцій в Інтернеті. Введення CVV/CVC-коду під час оформлення заявки дає змогу додатково захистити транзакцію від шахрайства.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Як ознайомитися з умовами кредитного договору?</div>
                                <div className="faq__body">
                                    У момент оформлення кожного окремого кредиту, перед отриманням коштів вам буде запропоновано ознайомитися з Договором позики із зазначенням параметрів конкретної операції та Правилами договору позики (Правила). Також чинний договір позики, після оформлення кредиту буде надіслано вам на e-mail, і протягом усього строку кредиту, договір позики буде доступний у вашому кабінеті.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Що таке електронний підпис одноразовим ідентифікатором?</div>
                                <div className="faq__body">
                                    Згідно зі ст. 12 ЗУ "Про електронну комерцію" - це алфавітно-цифрова послідовність символів, що ідентифікує вас у системі. Прикладом одноразового ідентифікатора може бути код, який надсилається користувачеві за допомогою sms або e-mail-повідомлення, під час реєстрації, що надалі використовується для входу в систему.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">На який термін можна отримати кредит?</div>
                                <div className="faq__body">
                                    Термін кредитування залежно від кредиту становить від 7 днів до 120 днів.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Як погасити кредит?</div>
                                <div className="faq__body">
                                    Перерахуванням коштів з банківської картки: у разі наявності коштів на будь-якій банківській картці (не обов'язково тій, на яку було оформлено кредит). Також ви можете погасити заборгованість в особистому кабінеті на сайті. Або ви можете погасити з банківської картки, використовуючи систему інтернет-банкінгу вашого банку, вказавши в параметрах одержувача платежу дані з договору.
                                    <br />
                                    <br />
                                    Погашенням готівкою: через будь-який платіжний термінал систем Citi24 (термінали в магазинах АТБ), вказавши як ідентифікатор номер вашого мобільного телефону, що зареєстрований у системі Groshi 24/7.
                                    <br />
                                    <br />
                                    Також, ви можете погасити готівкою через будь-який банк, вказавши в реквізитах одержувача дані з договору.
                                    <br />
                                    <br />
                                    Суму до погашення ви завжди можете перевірити у своєму кабінеті.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Чи можу я достроково погасити кредит?</div>
                                <div className="faq__body">
                                    Так, ви можете погасити кредит у будь-який день без штрафних санкцій за дострокове погашення. Перед здійсненням платежу перевірте суму до сплати у вашому особистому кабінеті.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Що буде якщо я не сплачу кредит вчасно?</div>
                                <div className="faq__body">
                                    У разі неможливості вчасно сплатити кредитні зобов'язання, вам слід негайно звернутися в кол-центр Гроші 24/7  для вирішення питання реструктуризації.
                                    <br />
                                    <br />
                                    Згідно з кредитним договором, у разі прострочення платежу більш ніж на 5 днів, відсоткова ставка за користування кредитом перераховується в більшу сторону.
                                    <br />
                                    <br />
                                    У разі прострочення платежу більше ніж 10 днів, інформація буде передана до бюро кредитних історій, що значно ускладнить отримання вами кредитів у майбутньому в будь-яких інших кредитних установах.
                                    <br />
                                    <br />
                                    У разі прострочення платежу більш ніж на 30 днів, організація залишає за собою право звернутися за стягненням до суду, або передати кредит до колекторської компанії.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Чи можна продовжити термін кредиту / пролонгація кредиту?</div>
                                <div className="faq__body">
                                    Так, пролонгація кредиту можлива. Функція стає доступною за 3 дні до терміну закінчення кредиту в особистому кабінеті користувача.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Чи є можливість пролонгувати термін користування кредитом?</div>
                                <div className="faq__body">
                                    Якщо у вас виникли труднощі з погашенням у строк кредиту та сплатою нарахованих відсотків, ви можете оформити пролонгацію кредиту. Функція пролонгації кредиту стає доступною в Особистому кабінеті за три дні до закінчення початкового терміну кредитування. Пролонгація оформляється за допомогою додаткової угоди до договору шляхом його підписання індивідуальним алфавітно-цифровим ідентифікатором. Оформити пролонгацію кредиту можна на будь-який термін до 30 днів. Для того, щоб пролонгація почала діяти, необхідно сплатити відсотки, нараховані за користування кредитом до моменту пролонгації. Пролонгувати кредит можна необмежену кількість разів. Пролонгація змінює тільки строк користування кредитом, але не процентну ставку. Під час пролонгації діє та сама відсоткова ставка, що й у момент початкового строку кредитування.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Коли можна оформити заявку на отримання повторного кредиту?</div>
                                <div className="faq__body">
                                    Через 5-10 хв., після погашення чинного кредиту.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Що таке особистий кабінет і навіщо він потрібен?</div>
                                <div className="faq__body">
                                    Особистий кабінет - це ваша персональна сторінка на сайті Groshi 24/7. В особистому кабінеті ви можете змінити свої персональні дані, оформити заявку, погасити кредит, отримати знижку на наступний кредит. Також там відображається актуальна інформація про поточний кредит, бонуси і всі попередньо оформлені кредити.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Не можу увійти в особистий кабінет, не підходить пароль.</div>
                                <div className="faq__body">
                                    Якщо ви забули пароль - відновіть пароль, натиснувши "Забули пароль" і слідуйте інструкціям.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Чому мені відмовлено в отриманні кредиту?</div>
                                <div className="faq__body">
                                    Найпоширеніші причини відмови: вам менше 18 або більше 60 років, у вас немає постійного доходу, ви вказуєте неправдиву інформацію про себе. Також вам може бути відмовлено, якщо у вас були значні прострочення за кредитами в минулому.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Якщо я отримав відмову, чи можу звертатися повторно?</div>
                                <div className="faq__body">
                                    Якщо ви вказали неправдиві дані, ви можете ще 2 рази спробувати оформити заявку повторно відразу після отримання відмови. У разі, навмисного зазначення неправдивих даних, системою буде заблокована можливість отримання вами кредиту на термін 90 днів.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Що таке бонуси?</div>
                                <div className="faq__body">
                                    Бонуси - це умовні одиниці, які зараховуються учаснику бонусної програми на бонусний рахунок і списуються з нього, на умовах, передбачених Правилами програми лояльності "BonusUP". Еквівалент бонусу: 1 бонус=1 гривні.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Які бувають бонуси?</div>
                                <div className="faq__body">
                                    Бонуси можуть бути планові та фактичні.
                                    <br />
                                    Плановий бонус відображається під час оформлення кредиту, отримання позитивного рішення і до моменту погашення кредиту. Плановий бонус не доступний до обміну.
                                    <br />
                                    Фактичний бонус - після повного погашення кредиту, плановий бонус переходить у статус фактичного, нараховується згідно з правилами нарахування залежно від суми фактичних витрат за кредитом. Наступного дня після погашення кредиту, фактичний бонус доступний до обміну.
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Що таке бонусний рахунок?</div>
                                <div className="faq__body">
                                    Бонусний рахунок - це спеціальний рахунок клієнта, на якому зберігаються бонуси. Стан бонусного рахунку відображається в програмі "Мій кабінет" у розділі "Мої бонуси".
                                </div>
                            </div>
                            <div className="faq__item">
                                <div className="faq__header">Не знайшли відповідь на своє запитання - телефонуйте нам!</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default FaqPage;
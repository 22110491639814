import React from "react";
import { Link } from "react-router-dom";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Button from "../components/Common/Button";
import rulesForLendingFundsFile from "../files/rules_for_lending_funds.pdf";
import agreementAboutConsumerCreditFile from "../files/Договір_про_споживчий_кредит_оновлений_відповідно_до_вимог_положення.pdf";
import balance32502022File from "../files/S0100114_Ф1_Баланс3250_2022.pdf";
import balance34082022File from "../files/S0100114_Ф1_Баланс3408_2022.pdf";
import balance35832022File from "../files/S0100114_Ф1_Баланс3583_2022.pdf";
import balance37862022File from "../files/S0100114_Ф1_Баланс3786_2022.pdf";
import balance39062023File from "../files/S0100114_Ф1_Баланс3906_2023.pdf";
import balance40192023File from "../files/S0100114_Ф1_Баланс4019_2023.pdf";
import reportOnFinancialResults32512022File from "../files/S0100214_Ф2_Звіт про_фінансові_результати_(в тисячах)3251_2022.pdf";
import reportOnFinancialResults34092022File from "../files/S0100214_Ф2_Звіт_про_фінансові_результати_(в тисячах)3409_2022.pdf";
import reportOnFinancialResults35842022File from "../files/S0100214_Ф2_Звіт_про_фінансові_результати_(в тисячах)3584_2022.pdf";
import reportOnFinancialResults37872022File from "../files/S0100214_Ф2_Звіт_про_фінансові_результати_(в тисячах)3787_2022.pdf";
import reportOnFinancialResults39072023File from "../files/S0100214_Ф2_Звіт_про_фінансові_результати_(в тисячах)3907_2023.pdf";
import reportOnFinancialResults40202023File from "../files/S0100214_Ф2_Звіт_про_фінансові_результати_(в тисячах)4020_2023.pdf";
import cashFlowStatement32522022File from "../files/S0100310_Ф3_Звiт_про_рух_грошових_коштiв_(за прямим методом)3252_2022.pdf";
import cashFlowStatement34152022File from "../files/S0100310_Ф3_Звiт_про_рух_грошових_коштiв_(за прямим методом)3415_2022.pdf";
import cashFlowStatement35852022File from "../files/S0100310_Ф3_Звiт_про_рух_грошових_коштiв_(за прямим методом)3585_2022.pdf";
import cashFlowStatement37882022File from "../files/S0100310_Ф3_Звiт_про_рух_грошових_коштiв_(за прямим методом)3788_2022.pdf";
import cashFlowStatement39082023File from "../files/S0100310_Ф3_Звiт_про_рух_грошових_коштiв_(за прямим методом)3908_2023.pdf";
import cashFlowStatement40212023File from "../files/S0100310_Ф3_Звiт_про_рух_грошових_коштiв_(за прямим методом)4021_2023.pdf";
import equityReport32532022tFile from "../files/S0104009_Ф4_Звіт_про_власний_капітал3253_2022.pdf";
import equityReport34172022tFile from "../files/S0104009_Ф4_Звіт_про_власний_капітал3417_2022.pdf";
import equityReport35862022tFile from "../files/S0104009_Ф4_Звіт_про_власний_капітал3586_2022.pdf";
import equityReport37892022tFile from "../files/S0104009_Ф4_Звіт_про_власний_капітал3789_2022.pdf";
import equityReport39092023tFile from "../files/S0104009_Ф4_Звіт_про_власний_капітал3909_2023.pdf";
import equityReport40222023tFile from "../files/S0104009_Ф4_Звіт_про_власний_капітал4022_2023.pdf";
import schemeOnSiteMoneyFile from "../files/схема_на_сайт_Гроші.pdf";
import informationAboutTheFinalFile from "../files/відомості_про_остаточних.pdf";
import privacyPolicyFile from "../files/Політика_конфіденційності.pdf";
import consumerCreditPassportFile from "../files/Паспорт_споживчого_кредиту.pdf";
import statusFile from "../files/Статут.PDF";
import certificateFile from "../files/Свідоцтво_Гроші_247_зміна_місцезнаходження.pdf";
import procedureSettlementOfOverdueDebtFile from "../files/Порядок,_спосіб_та_умови_врегулювання_простроченої_заборгованості.pdf";
import PhoneComponent from "../components/Common/PhoneComponent";

const AboutUs = () => {
    return (
        <div className="about_us">
            <div className="top_background">
                <div className="container">
                    <Header activePageName="about-us" />
                    <div className="about_us__wrapper">
                        <div className="title">Про нас</div>
                        <div className="about_us__block">
                            <div className="about_us__item">
                                <div className="about_us__left">
                                    Скорочене найменування
                                </div>
                                <div className="about_us__right">
                                    ТОВ «ГРОШІ 247»
                                </div>
                            </div>
                            <div className="about_us__item">
                                <div className="about_us__left">
                                    ЄДРПОУ
                                </div>
                                <div className="about_us__right">
                                    41803222
                                </div>
                            </div>
                            <div className="about_us__item">
                                <div className="about_us__left">
                                    Місцезнаходження фінансової установи
                                </div>
                                <div className="about_us__right">
                                    03061, М.КИЇВ, ВУЛИЦЯ ГЕРОЇВ СЕВАСТОПОЛЯ, БУДИНОК 48, КІМНАТА 214
                                </div>
                            </div>
                            <div className="about_us__item">
                                <div className="about_us__left">
                                    Телефон/факс
                                </div>
                                <div className="about_us__right">
                                    <PhoneComponent />
                                </div>
                            </div>
                            <div className="about_us__item">
                                <div className="about_us__left">
                                    E-mail
                                </div>
                                <div className="about_us__right">
                                    info@groshi247.com
                                </div>
                            </div>
                            <div className="about_us__item">
                                <div className="about_us__left">
                                    Сайт
                                </div>
                                <div className="about_us__right">
                                    https://groshi247.com/
                                </div>
                            </div>
                            <div className="about_us__item">
                                <div className="about_us__left">
                                    Перелік фінансових послуг, що надаються фінансовою установою
                                </div>
                                <div className="about_us__right">
                                    НАДАННЯ ФІНАНСОВИХ КРЕДИТІВ ЗА РАХУНОК ВЛАСНИХ КОШТІВ, ФАКТОРИНГ<br />
                                    Товариство не пропонує клієнтам фінансові послуги, що надаються іншими фінансовими установами.
                                </div>
                            </div>
                            <div className="about_us__item">
                                <div className="about_us__left">
                                    Відомості про склад наглядової ради та виконавчого органу фінан
                                </div>
                                <div className="about_us__right">
                                    Директор: Тонкошкуренко Олена Олександрівна<br />
                                    Головний бухгалтер: Бобров Денис Юрійович<br />
                                    Наглядова рада відсутня. Обов'язкове утворення наглядової ради законодавством не вимагається.<br />
                                    У власності членів виконавчого органу часток Товариства немає.
                                </div>
                            </div>
                            <div className="about_us__item about_us__color_white flex_direction_row">
                                <div className="about_us__left">
                                    Статут
                                </div>
                                <div className="about_us__right">
                                    <Link to={statusFile} target="_blank" download>
                                        <Button pink>Завантажити</Button>
                                    </Link>
                                </div>
                            </div>
                            <div className="about_us__item about_us__color_white flex_direction_row">
                                <div className="about_us__left">
                                    Свідоцтво про реєстрацію фінустанови
                                </div>
                                <div className="about_us__right">
                                    <Link to={certificateFile} target="_blank" download>
                                        <Button pink>Завантажити</Button>
                                    </Link>
                                </div>
                            </div>
                            <div className="about_us__item about_us__color_bluish_gray">
                                <div className="about_us__left">
                                    Відомості про відокремлені підрозділи фінансової установи
                                </div>
                                <div className="about_us__right">
                                    Відокремленних підрозділів Товариство не має.
                                </div>
                            </div>
                            <div className="about_us__item about_us__color_bluish_gray">
                                <div className="about_us__left">
                                    Відомості про ліцензії та дозволи, видані фінансовій установі
                                </div>
                                <div className="about_us__right">
                                    <div style={{ wordBreak: 'break-word' }}>1) Дата та номер запису в ЄДР юридичних осіб та фізичних осіб підприємців: Дата реєстрації: 26.04.2018р. Номер: 13103762</div>
                                    <div style={{ wordBreak: 'break-word' }}>2) Свідоцтво Нацкомфінпослуг про реєстрацю фінансової установи: Дата реєстрації: 26.04.2018р. Реєстраційний номер: 13103762 Серія свідоцтва: ФК Номер свідоцтва: 1027</div>
                                    <div style={{ wordBreak: 'break-word' }}>3) Ліцензія Нацкомфінпослуг на надання коштів у позику, в тому числі і на умовах фінансового кредиту: Початок дії : 26.06.2018. Рішення : №1097 від 26.06.2018.</div>
                                    <div style={{ wordBreak: 'break-word' }}>4) Ліцензія Нацкомфінпослуг на надання послуг з факторингу: Початок дії : 26.06.2018. Рішення : №1097 від 26.06.2018.</div>
                                    <div style={{ wordBreak: 'break-word' }}>5) Ліцензії на діяльність фінансової компанії з правом надання послуг – факторингу, надання коштів та банківських металів у кредит, внесено Національним банком України до Державного реєстру фінансових установ 15.03.2024 року.</div>
                                    <div style={{ wordBreak: 'break-word' }}>6) Рішення про тимчасове зупиненя ліцензії не приймалось.</div>
                                    <div style={{ wordBreak: 'break-word' }}>7) Ліцензія чинна.</div>
                                    <div style={{ wordBreak: 'break-word' }}>8) Ліцензія не анульовувалась.</div>
                                    <div style={{ wordBreak: 'break-word' }}>9) Дозволи фінансовій установі не видавались.</div>
                                    <div style={{ wordBreak: 'break-word' }}>10) Провадження у справі про банкрутство не порушувалось, процедура санації не застосовувалась.</div>
                                    <div style={{ wordBreak: 'break-word' }}>11) Процедура ліквідації фінансової установи не починалась.</div>
                                    <div style={{ wordBreak: 'break-word' }}>12) Гіперпосилання на сторінку офіційного Інтернет-представництва Національного банку, на якій розміщено Державний реєстр фінансових установ - <a href="https://kis.bank.gov.ua/">kis.bank.gov.ua</a></div>
                                </div>
                            </div>
                            <div className="about_us__item about_us__color_white">
                                <div className="about_us__left">
                                    Річна фінансова та консолідована фінансова звітність
                                </div>
                                <div className="about_us__right">
                                    <div>
                                        <details>
                                            <summary style={{ cursor: "pointer" }}>Річна фінансова фінансова звітність за 2022 рік</summary>
                                            <div className="about_us__list_item" style={{ marginTop: "10px" }}>
                                                <div>
                                                    Звіт про фінансові результати 1 квартал
                                                </div>
                                                <div>
                                                    <Link to={reportOnFinancialResults32512022File} target="_blank" download>
                                                        <Button pink>Завантажити</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="about_us__list_item" style={{ marginTop: "10px" }}>
                                                <div>
                                                    Звіт про фінансові результати 2 квартал
                                                </div>
                                                <div>
                                                    <Link to={reportOnFinancialResults34092022File} target="_blank" download>
                                                        <Button pink>Завантажити</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="about_us__list_item" style={{ marginTop: "10px" }}>
                                                <div>
                                                    Звіт про фінансові результати 3 квартал
                                                </div>
                                                <div>
                                                    <Link to={reportOnFinancialResults35842022File} target="_blank" download>
                                                        <Button pink>Завантажити</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="about_us__list_item" style={{ marginTop: "10px" }}>
                                                <div>
                                                    Звіт про фінансові результати 4 квартал
                                                </div>
                                                <div>
                                                    <Link to={reportOnFinancialResults37872022File} target="_blank" download>
                                                        <Button pink>Завантажити</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="about_us__list_item" style={{ marginTop: "10px" }}>
                                                <div>
                                                    Звіт про рух грошових коштів (за прямим методом) 1 квартал
                                                </div>
                                                <div>
                                                    <Link to={cashFlowStatement32522022File} target="_blank" download>
                                                        <Button pink>Завантажити</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="about_us__list_item" style={{ marginTop: "10px" }}>
                                                <div>
                                                    Звіт про рух грошових коштів (за прямим методом) 2 квартал
                                                </div>
                                                <div>
                                                    <Link to={cashFlowStatement34152022File} target="_blank" download>
                                                        <Button pink>Завантажити</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="about_us__list_item" style={{ marginTop: "10px" }}>
                                                <div>
                                                    Звіт про рух грошових коштів (за прямим методом) 3 квартал
                                                </div>
                                                <div>
                                                    <Link to={cashFlowStatement35852022File} target="_blank" download>
                                                        <Button pink>Завантажити</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="about_us__list_item" style={{ marginTop: "10px" }}>
                                                <div>
                                                    Звіт про рух грошових коштів (за прямим методом) 4 квартал
                                                </div>
                                                <div>
                                                    <Link to={cashFlowStatement37882022File} target="_blank" download>
                                                        <Button pink>Завантажити</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="about_us__list_item" style={{ marginTop: "10px" }}>
                                                <div>
                                                    Звіт про власний капітал 1 квартал
                                                </div>
                                                <div>
                                                    <Link to={equityReport32532022tFile} target="_blank" download>
                                                        <Button pink>Завантажити</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="about_us__list_item" style={{ marginTop: "10px" }}>
                                                <div>
                                                    Звіт про власний капітал 2 квартал
                                                </div>
                                                <div>
                                                    <Link to={equityReport34172022tFile} target="_blank" download>
                                                        <Button pink>Завантажити</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="about_us__list_item" style={{ marginTop: "10px" }}>
                                                <div>
                                                    Звіт про власний капітал 3 квартал
                                                </div>
                                                <div>
                                                    <Link to={equityReport35862022tFile} target="_blank" download>
                                                        <Button pink>Завантажити</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="about_us__list_item" style={{ marginTop: "10px" }}>
                                                <div>
                                                    Звіт про власний капітал 4 квартал
                                                </div>
                                                <div>
                                                    <Link to={equityReport37892022tFile} target="_blank" download>
                                                        <Button pink>Завантажити</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="about_us__list_item" style={{ marginTop: "10px" }}>
                                                <div>
                                                    Баланс 1 квартал
                                                </div>
                                                <div>
                                                    <Link to={balance32502022File} target="_blank" download>
                                                        <Button pink>Завантажити</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="about_us__list_item" style={{ marginTop: "10px" }}>
                                                <div>
                                                    Баланс 2 квартал
                                                </div>
                                                <div>
                                                    <Link to={balance34082022File} target="_blank" download>
                                                        <Button pink>Завантажити</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="about_us__list_item" style={{ marginTop: "10px" }}>
                                                <div>
                                                    Баланс 3 квартал
                                                </div>
                                                <div>
                                                    <Link to={balance35832022File} target="_blank" download>
                                                        <Button pink>Завантажити</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="about_us__list_item" style={{ marginTop: "10px" }}>
                                                <div>
                                                    Баланс 4 квартал
                                                </div>
                                                <div>
                                                    <Link to={balance37862022File} target="_blank" download>
                                                        <Button pink>Завантажити</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </details>
                                    </div>
                                    <div>
                                        <details>
                                            <summary style={{ cursor: "pointer" }}>Річна фінансова фінансова звітність за 2023 рік</summary>
                                            <div className="about_us__list_item" style={{ marginTop: "10px" }}>
                                                <div>
                                                    Звіт про фінансові результати 1 квартал
                                                </div>
                                                <div>
                                                    <Link to={reportOnFinancialResults39072023File} target="_blank">
                                                        <Button pink>Завантажити</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="about_us__list_item" style={{ marginTop: "10px" }}>
                                                <div>
                                                    Звіт про фінансові результати 2 квартал
                                                </div>
                                                <div>
                                                    <Link to={reportOnFinancialResults40202023File} target="_blank">
                                                        <Button pink>Завантажити</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="about_us__list_item" style={{ marginTop: "10px" }}>
                                                <div>
                                                    Звіт про рух грошових коштів (за прямим методом) 1 квартал
                                                </div>
                                                <div>
                                                    <Link to={cashFlowStatement39082023File} target="_blank" download>
                                                        <Button pink>Завантажити</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="about_us__list_item" style={{ marginTop: "10px" }}>
                                                <div>
                                                    Звіт про рух грошових коштів (за прямим методом) 2 квартал
                                                </div>
                                                <div>
                                                    <Link to={cashFlowStatement40212023File} target="_blank" download>
                                                        <Button pink>Завантажити</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="about_us__list_item" style={{ marginTop: "10px" }}>
                                                <div>
                                                    Звіт про власний капітал 1 квартал
                                                </div>
                                                <div>
                                                    <Link to={equityReport39092023tFile} target="_blank" download>
                                                        <Button pink>Завантажити</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="about_us__list_item" style={{ marginTop: "10px" }}>
                                                <div>
                                                    Звіт про власний капітал 2 квартал
                                                </div>
                                                <div>
                                                    <Link to={equityReport40222023tFile} target="_blank" download>
                                                        <Button pink>Завантажити</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="about_us__list_item" style={{ marginTop: "10px" }}>
                                                <div>
                                                    Баланс 1 квартал
                                                </div>
                                                <div>
                                                    <Link to={balance39062023File} target="_blank" download>
                                                        <Button pink>Завантажити</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="about_us__list_item" style={{ marginTop: "10px" }}>
                                                <div>
                                                    Баланс 2 квартал
                                                </div>
                                                <div>
                                                    <Link to={balance40192023File} target="_blank" download>
                                                        <Button pink>Завантажити</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </details>
                                    </div>
                                </div>
                            </div>
                            <div className="about_us__item about_us__color_bluish_gray">
                                <div className="about_us__left">
                                    Інформація, щодо відступлення права грошової вимоги
                                </div>
                                <div className="about_us__right">
                                    1) Договір доручення №12/09/22-гр від 22.09.2022 року, укладений між ТОВ «ГРОШІ 247» та ТДВ «Інтер-Ріск Україна». Кількість фізичних осіб- споживачів фінансових послуг в момент передачі складає: 1 000,00 (одна тисяча) осіб.<br />
                                    2) Договір доручення №13/09/22-гр від 22.09.2022 року, укладений між ТОВ «ГРОШІ 247» та ТОВ «УКРБОРГ». Кількість фізичних осіб- споживачів фінансових послуг в момент передачі складає: 1 000,00 (одна тисяча) осіб.
                                </div>
                            </div>
                            <div className="about_us__item about_us__color_white">
                                <div className="about_us__left">
                                    Структура власності
                                </div>
                                <div className="about_us__right">
                                    <div className="about_us__list">
                                        <div className="about_us__list_item">
                                            <div>
                                                Відомості про власників
                                            </div>
                                            <div>
                                                <Link to={informationAboutTheFinalFile} target="_blank" download>
                                                    <Button pink>Завантажити</Button>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="about_us__list_item">
                                            <div>
                                                Відомості про кінцевих бенефіціарів
                                            </div>
                                            <div>
                                                <Link to={informationAboutTheFinalFile} target="_blank" download>
                                                    <Button pink>Завантажити</Button>
                                                </Link>
                                            </div>
                                        </div>
                                        <div className="about_us__list_item">
                                            <div>
                                                Схема власності
                                            </div>
                                            <div>
                                                <Link to={schemeOnSiteMoneyFile} target="_blank" download>
                                                    <Button pink>Завантажити</Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="about_us__item about_us__color_white">
                                <div className="about_us__left">
                                    Договір про споживчий кредит
                                </div>
                                <div className="about_us__right">
                                    <Link to={agreementAboutConsumerCreditFile} target="_blank" download>
                                        <Button pink>Завантажити</Button>
                                    </Link>
                                </div>
                            </div>
                            <div className="about_us__item about_us__color_white">
                                <div className="about_us__left">
                                    Паспорт споживчого кредиту
                                </div>
                                <div className="about_us__right">
                                    <Link to={consumerCreditPassportFile} target="_blank" download>
                                        <Button pink>Завантажити</Button>
                                    </Link>
                                </div>
                            </div>
                            <div className="about_us__item about_us__color_white">
                                <div className="about_us__left">
                                    Політика конфіденційності
                                </div>
                                <div className="about_us__right">
                                    <Link to={privacyPolicyFile} target="_blank" download>
                                        <Button pink>Завантажити</Button>
                                    </Link>
                                </div>
                            </div>
                            <div className="about_us__item about_us__color_white">
                                <div className="about_us__left">
                                    Порядок врегулювання простроченої заборгованості
                                </div>
                                <div className="about_us__right">
                                    <Link to={procedureSettlementOfOverdueDebtFile} target="_blank" download>
                                        <Button pink>Завантажити</Button>
                                    </Link>
                                </div>
                            </div>
                            <div className="about_us__item about_us__color_white">
                                <div className="about_us__left">
                                    Правила надання грошових коштів у позику у формі споживчого кредиту
                                </div>
                                <div className="about_us__right">
                                    <Link to={rulesForLendingFundsFile} target="_blank" download>
                                        <Button pink>Завантажити</Button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default AboutUs;
const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(',')[1]);
        reader.onerror = (error) => reject(error);
    });
};
export const getFileToMessage = async (file) => {

    if (!file || !file[0]) return []
    const attachments = [];
    const base64File = await convertFileToBase64(file[0]);

    attachments.push({ name: file[0].name, data: base64File, });
    return attachments
}